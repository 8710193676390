<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" class="mx-auto" cols="12"
            sm="8">
                <v-col align="start" justify="start">
                    <v-btn 
                    @click="redirectBack"
                    class="mb-3"
                    color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn> 
                </v-col>
                <v-card class="pa-3" elevation-1>
                    <v-toolbar class = "white--text text-h5 mb-4" color="secondary">
                        Register new sales team
                    </v-toolbar>
                    <AWarning
                    :message = "messageInputInvalid"
                    v-if="isInputInvalid"
                    />
                    <ASuccessThree
                    :api="this.api"/>
                    <v-col>
                        <div class="d-flex justify-start">
                            <v-text-field
                            dense
                            outlined
                            v-model="form.name"
                            label="Team name">
                            </v-text-field>
                        </div>
                        <div class="d-flex justify-start">
                            <v-text-field
                            dense
                            counter="100"
                            outlined
                            v-model="form.description"
                            label="description">
                            </v-text-field>
                        </div>
                        <v-autocomplete
                        clearable
                        v-model="form.leader"
                        outlined 
                        dense
                        filled
                        small-chips
                        item-text="staff_name"
                        item-value="email"
                        :items="leaderOptions"
                        label="Leader"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                        deletable-chips
                        required
                        clearable
                        v-model="form.members"
                        outlined 
                        dense
                        filled
                        small-chips
                        :items="membersOptions"
                        item-text="staff_name"
                        item-value="email"
                        label="Member(s)"
                        multiple>
                        </v-autocomplete>
                        <div class="d-flex justify-end">
                            <v-btn color="primary" @click="redirectBack" plain>
                                Cancel
                            </v-btn>
                            <v-btn :loading="api.isLoading" color="primary" @click="validateInput">
                                Submit
                            </v-btn>
                            <!-- <v-col>
                                <div>

                                </div>
                                List of team member
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title
                                        v-for="(item,index) in members" :key="index">
                                        {{ item }}
                                    </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-col> -->
                        </div>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessThree from '../../components/common/ASuccessThree.vue';
import AWarning from '../../components/common/AWarning.vue';


export default{
    components:{
    ASuccessThree,
    AWarning,
    AConfirmation
},
    data:()=>({
        isPending:false,
        isInputInvalid:false,
        messageInputInvalid:null,
        api:{
            isLoading:false,
            isError:false,
            isSuccesful:false,
            sucess:"",
            error:null,
            url:null,
        },
        form:{
            name:null,
            userEmail:null,
            description:null,
            leader:null,
            members:[],
        },
        membersOptions:[],
        leaderOptions:[],
        // membersOptions:
        // ['salespersonone','salespersontwo','salespersonthree','four','five','six','seven','eight','nine','ten'],
        // leaderOptions:['leader 1','leader 2','leader 3','leader 4','leader 5','leader 6','leader 7'],
    }),
    created(){
        this.api.callbackReset = () => 
        {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => 
        {
            if(resp.class=="getAllRole"){
                if(resp.data[0].role=='salesperson'){
                    this.membersOptions = resp.data;
                }
                if(resp.data[0].role=='sales_team_lead'){
                    this.leaderOptions = resp.data;
                }
            }
            if(resp.class=="createTeam"){
                this.api.isSuccesful = true;
                this.api.success = "Sucessfully create new team";
                this.$router.push({name:'PageTeamList'})
            }
            this.api.isError = false;
            this.api.isLoading = false;
            return resp.data;
        }
    },
    mounted(){
        this.fetch()
    },
    methods:{
        fetchSalesperson(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET",
            tempApi.url = process.env.VUE_APP_SERVER_API+"/team/role/salesperson";
            return tempApi;
        }, 
        fetchSalesTeamLead()
        {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET",
            tempApi.url = process.env.VUE_APP_SERVER_API+"/user/role/v2/sales_team_lead";
            return tempApi;
        },
        fetch()
        {
            let fetchSalespersonApi = this.fetchSalesperson();
            let fetchSalesTeamLeadApi = this.fetchSalesTeamLead();
            this.$axios.all([
                this.$api.fetch(fetchSalespersonApi),
                this.$api.fetch(fetchSalesTeamLeadApi)
            ])
        },
        create(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/team";
            this.form.userEmail = this.$store.getters.getEmail;
            tempApi.params = this.$_.clone(this.form);
            return tempApi;
        },
        validateInput(){
            this.isInputInvalid = false;
            if(this.form.members.length==0){
                this.isInputInvalid = true;
                this.messageInputInvalid = "please fill in at least 1 member";
                return 0;
            }
            this.isPending = true;
        },
        submit(){
            this.isPending = false;
            if(this.isInputInvalid){
                return 0;
            }
            let createApi = this.create();
            this.$axios.all([
                this.$api.fetch(createApi),
            ]);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        selectSalesperson(){
            this.$router.push({name:'MockupFindSalesperson'});
        },
        redirectBack(){
            this.$router.push({name:'PageTeamList'});
        },
    }   
}
</script>